body {
	margin: 0;

	color: #404040;
	background-color: #f9f9f9;

	font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'FZFangSong', 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body {
	scroll-behavior:smooth;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.react-icons {
	vertical-align: middle;
}

button {
    margin: 0;
    padding: 0;

    cursor: pointer;

	border: 0;
    border-radius: 0;
    background: none;
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
    padding: 0;
}

.h2-style {
	color: #212529;

	font-size: 18px;
}

a {
	text-decoration: none;
}

*, :after, :before {
    box-sizing: border-box;
}

.split-line {
	width: 100%;
	height: 1px;
	margin: 14px 0;

	background-color: #eee;
}

@media (max-width: 1250px) {
	.App .layout-responsive {
		width: 960px;
	}
}

@media (max-width: 580px) {
	.App .layout-responsive,
	.App .home-main,
	.App .home-aside {
		width: 100%;
	}

	.App .home-main {
		margin-bottom: 10px;
	}

	.App .home-container {
		flex-direction: column;
	}

	.App .back-button {
		width: 35px;
		height: 35px;
	}
}

@font-face {
    font-family: FZHei;

    src:url('./assets/fonts/FZHTJW.TTF')
}

@font-face {
    font-family: FZFangSong;

    src:url('./assets/fonts/FZSSJW.TTF')
}
.back-button {
    position: fixed;
    z-index: 1;
    top: 60px;
    left: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;

    opacity: 0.7;
    color: #ffffff;
    background-color: #456;

    font-size: 30px;

    &:hover {
        cursor: pointer;
    }
}